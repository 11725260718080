import type {LocationQueryValue} from 'vue-router'

export const stringIsInteger = (str: string | null) => {
  if (!str) {
    return false
  }

  return /^\+?(0|[1-9]\d*)$/.test(str)
}

export const getSingleValue = (v: null | LocationQueryValue | LocationQueryValue[]): string | null => {
  if (!v) {
    return null
  }

  return Array.isArray(v) ? null : v.toString()
}

export const routeIsAwaitingLocalizedRedirection = (): boolean => {
  if (useNuxtApp().$router.currentRoute.value.path === '/logout') {
    return false
  }

  return useNuxtApp().$getRouteBaseName() === useNuxtApp().$router.currentRoute.value.name
}