import {useUserStore} from '~/stores/user'
import type {UserData} from '~/types/UserData'
import type {RedirectData} from '~/types/RedirectData'
import type {ApiFetchOptions} from '~/composables/useApiFetch'

const { objectToFormData } = useFormData()

//TODO: translate
const authError = ref<string|null>(null)
const isPending = ref<boolean>(false)

async function signIn(username: string, password: string) {
    if (isPending.value) {
        return
    }

    isPending.value = true

    let body = new FormData
    body.append('username', username)
    body.append('password', password)

    const {error} = await useBaseApiFetch('/login', {method: 'post', body})

    if (error.value) {
        isPending.value = false
        authError.value = error.value.data.error
        return false
    }

    authError.value = null

    const backendRedirectData = await getSession()

    if (backendRedirectData) {
        return navigateTo(backendRedirectData.redirect, {external: true})
    }

    // Front-end redirect (with `next` query param)
    let route = useRoute()
    if (route.query.next) {
        await navigateTo(route.query.next.toString())
    } else {
        await navigateTo(useNuxtApp().$localePath('index'))
    }

    isPending.value = false

    return true
}

function resetAuthError() {
    authError.value = null
}

function isRedirectData(o: UserData|RedirectData): o is RedirectData {
    return Object.keys(o).indexOf('redirect') > -1
}

async function getSession(query?: any|null, requestPostData?: any|null): Promise<RedirectData | null> {
    const method = requestPostData ? 'post' : 'get'

    const options: ApiFetchOptions = {
        method,
        query,
        noCache: true,
    }

    if (requestPostData) {
        options.body = objectToFormData(requestPostData)
    }

    const {data, error} = await useApiFetch<UserData|RedirectData>('/user', options)

    const userStore = useUserStore()

    if (error.value) {
        userStore.resetData(error.value.data)

        return Promise.reject(null)
    }

    if (data.value) {
        if (isRedirectData(data.value)) {
            return data.value
        }

        userStore.setData(data.value)
    }

    return null
}

async function refreshSession(): Promise<RedirectData | null> {
    return await getSession()
}

async function signOut(updateClientLocation = true) {
    if (process.server) {
        console.error('signOut() is not available on server side')

        return
    }

    const {data} = await useBaseApiFetch<UserData>('/logout', {noCache: true})
    useUserStore().resetData(data.value)

    if (updateClientLocation) {
        window.location.href = useNuxtApp().$localePath('index')
    }
}

export const useSession = () => {
    return {
        signIn,
        isPending,
        authError,
        resetAuthError,
        getSession,
        refreshSession,
        signOut,
    }
}