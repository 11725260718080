import {defineStore} from "pinia"
import type {SearchResult} from '~/types/SearchResult'
import type {SearchHitMediaData} from '~/types/media/SearchHitMediaData'
import type {SearchHitMediaChapter} from '~/types/media/SearchHitMediaChapter'
import type {SearchHitMediaNode} from '~/types/media/SearchHitMediaNode'

export const useSearchStore = defineStore('search', () => {

    const {result, search: algoliaSearch} = useAlgoliaSearch()

    const queryString = ref<string>('')

    const hasQueryString = computed(() => {
        return queryString.value !== ''
    })

    function resetQueryString() {
        queryString.value = ''
    }

    const fieldIsFocused = ref<boolean>(false)

    let focusTimeout:NodeJS.Timeout

    function onFieldFocused() {
        clearTimeout(focusTimeout)
        fieldIsFocused.value = true
    }

    function onFieldBlurred() {
        if (!process.client) {
            return
        }

        focusTimeout = setTimeout(() => {
            fieldIsFocused.value = false
        }, 100)
    }

    const hits = computed<SearchHitMediaData[]|null>(() => {
        if (!hasQueryString.value) {
            return null
        }

        return result.value?.hits
    })

    const hasHits = computed(() => {
        return hits.value !== null
    })

    const domainStore = useDomainDataStore()

    watch(queryString, (query: string) => {
        if (query === '') {
            return
        }

        const locale = useNuxtApp().$i18n.locale.value

        algoliaSearch({
            query,
            requestOptions: {
                hitsPerPage: 6,
                facetFilters: ['locale:' + locale, 'status:published', 'catalogs:' + domainStore.domainData.domain],
                naturalLanguages: [locale]
            }
        });
    })

    const showSuggestions = computed(() => {
        return fieldIsFocused.value && hasHits.value
    })

    const chapters = computed<SearchHitMediaChapter[]>(() => {
        if (!hits.value) {
            return []
        }

        const list:{[key: number]: SearchHitMediaChapter} = {}
        hits.value.forEach((item: SearchHitMediaData) => {
            item.nodes.forEach((node: SearchHitMediaNode) => {
                if (list[node.id]) {
                    list[node.id].score++
                } else {
                    list[node.id] = {
                        id: node.id,
                        name: node.name,
                        slug: node.slug,
                        score: 1,
                    }
                }
            })
        })

        return Object.values(list).sort((a: any, b: any) => {
            return a.score > b.score ? -1 : 1
        }).slice(0, 3)
    })

    const extraHitCount = computed(() => {
        return hits.value ? result.value.nbHits - hits.value.length : 0
    })

    const isSearchPage = computed(() => {
        return useNuxtApp().$getRouteBaseName() === 'search'
    })

    const searchPageQuery = computed(() => {
        return isSearchPage.value ? useRoute().query.q : null
    })

    const searches = ref<{[key: string]: SearchResult}>({})
    const currentSearchQuery = ref<string|null>(null)

    async function loadSearchData(q: string) {
        const {data} = await useApiFetch<SearchResult>(`/search`, {params: {q}})

        if (data.value) {
            searches.value[q] = data.value
        }

        return data.value
    }

    async function search(q: string, andMakeCurrent = true) {
        let result

        if (andMakeCurrent) {
            currentSearchQuery.value = q
        }

        if (Object.keys(searches.value).indexOf(q) === -1) {
            result = await loadSearchData(q)
        } else {
            result = searches.value[q]
        }

        return result
    }

    const currentSearch = computed(() => {
        return currentSearchQuery.value ? searches.value[currentSearchQuery.value] : null
    })

    const searchMedias = computed(() => {
        return currentSearch.value?.medias || []
    })

    return {
        queryString,

        showSuggestions,
        hasQueryString,
        resetQueryString,
        result,
        hasHits,
        hits,
        extraHitCount,
        chapters,
        isSearchPage,
        searchPageQuery,
        onFieldFocused,
        onFieldBlurred,

        search,
        // searches,
        // currentSearch,
        currentSearchQuery,
        searchMedias,
    }
})