import { default as _91code_93GNw5GcQdhkMeta } from "/app/pages/capture/[code].vue?macro=true";
import { default as _91code_93riYJ593c3NMeta } from "/app/pages/collection/[code].vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as capturesowGdxFIZXIMeta } from "/app/pages/me/captures.vue?macro=true";
import { default as collectionsLrlBXWS5NxMeta } from "/app/pages/me/collections.vue?macro=true";
import { default as hellotsbKugx1ZDMeta } from "/app/pages/me/hello.vue?macro=true";
import { default as indexnwyNS3d1VsMeta } from "/app/pages/me/index.vue?macro=true";
import { default as _91id_93_45_91slug_93otQ9BbVv3jMeta } from "/app/pages/media/[id]-[slug].vue?macro=true";
import { default as _91pendingCode_93FzFFUnKu0iMeta } from "/app/pages/new-account/[pendingCode].vue?macro=true";
import { default as _91id_93_45_91slug_93yMjQcjSjulMeta } from "/app/pages/node/[id]-[slug].vue?macro=true";
import { default as _91slug_93jOfXxJvwBLMeta } from "/app/pages/page/[slug].vue?macro=true";
import { default as _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta } from "/app/pages/password-reset/[user]-[institution]-[hash].vue?macro=true";
import { default as indexYgRHYxGzjsMeta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as estimateBvWtG1sZBQMeta } from "/app/pages/subscribe/estimate.vue?macro=true";
import { default as indexSgs8AqJpW6Meta } from "/app/pages/subscribe/index.vue?macro=true";
import { default as successqYsoTEk1d4Meta } from "/app/pages/subscribe/success.vue?macro=true";
import { default as teachers_45areauZqTSTDHI2Meta } from "/app/pages/teachers-area.vue?macro=true";
export default [
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___en",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/en/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___fr",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/fr/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___es",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/es/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___de",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/de/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___nl",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/nl/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93GNw5GcQdhkMeta?.name ?? "capture-code___ar",
    path: _91code_93GNw5GcQdhkMeta?.path ?? "/ar/capture/:code()",
    meta: _91code_93GNw5GcQdhkMeta || {},
    alias: _91code_93GNw5GcQdhkMeta?.alias || [],
    redirect: _91code_93GNw5GcQdhkMeta?.redirect,
    component: () => import("/app/pages/capture/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___en",
    path: _91code_93riYJ593c3NMeta?.path ?? "/en/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___fr",
    path: _91code_93riYJ593c3NMeta?.path ?? "/fr/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___es",
    path: _91code_93riYJ593c3NMeta?.path ?? "/es/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___de",
    path: _91code_93riYJ593c3NMeta?.path ?? "/de/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___nl",
    path: _91code_93riYJ593c3NMeta?.path ?? "/nl/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93riYJ593c3NMeta?.name ?? "collection-code___ar",
    path: _91code_93riYJ593c3NMeta?.path ?? "/ar/collection/:code()",
    meta: _91code_93riYJ593c3NMeta || {},
    alias: _91code_93riYJ593c3NMeta?.alias || [],
    redirect: _91code_93riYJ593c3NMeta?.redirect,
    component: () => import("/app/pages/collection/[code].vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___en",
    path: contactV6oPJjs9RyMeta?.path ?? "/en/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___fr",
    path: contactV6oPJjs9RyMeta?.path ?? "/fr/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___es",
    path: contactV6oPJjs9RyMeta?.path ?? "/es/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___de",
    path: contactV6oPJjs9RyMeta?.path ?? "/de/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___nl",
    path: contactV6oPJjs9RyMeta?.path ?? "/nl/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: contactV6oPJjs9RyMeta?.name ?? "contact___ar",
    path: contactV6oPJjs9RyMeta?.path ?? "/ar/contact",
    meta: contactV6oPJjs9RyMeta || {},
    alias: contactV6oPJjs9RyMeta?.alias || [],
    redirect: contactV6oPJjs9RyMeta?.redirect,
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/fr",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___es",
    path: indexN6pT4Un8hYMeta?.path ?? "/es",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___de",
    path: indexN6pT4Un8hYMeta?.path ?? "/de",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___nl",
    path: indexN6pT4Un8hYMeta?.path ?? "/nl",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ar",
    path: indexN6pT4Un8hYMeta?.path ?? "/ar",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___en",
    path: loginhHM0vSTW5jMeta?.path ?? "/en/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___fr",
    path: loginhHM0vSTW5jMeta?.path ?? "/fr/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___es",
    path: loginhHM0vSTW5jMeta?.path ?? "/es/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___de",
    path: loginhHM0vSTW5jMeta?.path ?? "/de/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___nl",
    path: loginhHM0vSTW5jMeta?.path ?? "/nl/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___ar",
    path: loginhHM0vSTW5jMeta?.path ?? "/ar/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutHaVXWqg8UAMeta?.name ?? "logout",
    path: logoutHaVXWqg8UAMeta?.path ?? "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    alias: logoutHaVXWqg8UAMeta?.alias || [],
    redirect: logoutHaVXWqg8UAMeta?.redirect,
    component: () => import("/app/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___en",
    path: capturesowGdxFIZXIMeta?.path ?? "/en/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___fr",
    path: capturesowGdxFIZXIMeta?.path ?? "/fr/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___es",
    path: capturesowGdxFIZXIMeta?.path ?? "/es/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___de",
    path: capturesowGdxFIZXIMeta?.path ?? "/de/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___nl",
    path: capturesowGdxFIZXIMeta?.path ?? "/nl/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: capturesowGdxFIZXIMeta?.name ?? "me-captures___ar",
    path: capturesowGdxFIZXIMeta?.path ?? "/ar/me/captures",
    meta: capturesowGdxFIZXIMeta || {},
    alias: capturesowGdxFIZXIMeta?.alias || [],
    redirect: capturesowGdxFIZXIMeta?.redirect,
    component: () => import("/app/pages/me/captures.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___en",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/en/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___fr",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/fr/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___es",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/es/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___de",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/de/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___nl",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/nl/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: collectionsLrlBXWS5NxMeta?.name ?? "me-collections___ar",
    path: collectionsLrlBXWS5NxMeta?.path ?? "/ar/me/collections",
    meta: collectionsLrlBXWS5NxMeta || {},
    alias: collectionsLrlBXWS5NxMeta?.alias || [],
    redirect: collectionsLrlBXWS5NxMeta?.redirect,
    component: () => import("/app/pages/me/collections.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___en",
    path: hellotsbKugx1ZDMeta?.path ?? "/en/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___fr",
    path: hellotsbKugx1ZDMeta?.path ?? "/fr/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___es",
    path: hellotsbKugx1ZDMeta?.path ?? "/es/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___de",
    path: hellotsbKugx1ZDMeta?.path ?? "/de/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___nl",
    path: hellotsbKugx1ZDMeta?.path ?? "/nl/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: hellotsbKugx1ZDMeta?.name ?? "me-hello___ar",
    path: hellotsbKugx1ZDMeta?.path ?? "/ar/me/hello",
    meta: hellotsbKugx1ZDMeta || {},
    alias: hellotsbKugx1ZDMeta?.alias || [],
    redirect: hellotsbKugx1ZDMeta?.redirect,
    component: () => import("/app/pages/me/hello.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___en",
    path: indexnwyNS3d1VsMeta?.path ?? "/en/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___fr",
    path: indexnwyNS3d1VsMeta?.path ?? "/fr/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___es",
    path: indexnwyNS3d1VsMeta?.path ?? "/es/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___de",
    path: indexnwyNS3d1VsMeta?.path ?? "/de/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___nl",
    path: indexnwyNS3d1VsMeta?.path ?? "/nl/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: indexnwyNS3d1VsMeta?.name ?? "me___ar",
    path: indexnwyNS3d1VsMeta?.path ?? "/ar/me",
    meta: indexnwyNS3d1VsMeta || {},
    alias: indexnwyNS3d1VsMeta?.alias || [],
    redirect: indexnwyNS3d1VsMeta?.redirect,
    component: () => import("/app/pages/me/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___en",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/en/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___fr",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/fr/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___es",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/es/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___de",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/de/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___nl",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/nl/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93otQ9BbVv3jMeta?.name ?? "media-id-slug___ar",
    path: _91id_93_45_91slug_93otQ9BbVv3jMeta?.path ?? "/ar/media/:id()-:slug()",
    meta: _91id_93_45_91slug_93otQ9BbVv3jMeta || {},
    alias: _91id_93_45_91slug_93otQ9BbVv3jMeta?.alias || [],
    redirect: _91id_93_45_91slug_93otQ9BbVv3jMeta?.redirect,
    component: () => import("/app/pages/media/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___en",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/en/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___fr",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/fr/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___es",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/es/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___de",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/de/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___nl",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/nl/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91pendingCode_93FzFFUnKu0iMeta?.name ?? "new-account-pendingCode___ar",
    path: _91pendingCode_93FzFFUnKu0iMeta?.path ?? "/ar/new-account/:pendingCode()",
    meta: _91pendingCode_93FzFFUnKu0iMeta || {},
    alias: _91pendingCode_93FzFFUnKu0iMeta?.alias || [],
    redirect: _91pendingCode_93FzFFUnKu0iMeta?.redirect,
    component: () => import("/app/pages/new-account/[pendingCode].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___en",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/en/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___fr",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/fr/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___es",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/es/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___de",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/de/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___nl",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/nl/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45_91slug_93yMjQcjSjulMeta?.name ?? "node-id-slug___ar",
    path: _91id_93_45_91slug_93yMjQcjSjulMeta?.path ?? "/ar/node/:id()-:slug()",
    meta: _91id_93_45_91slug_93yMjQcjSjulMeta || {},
    alias: _91id_93_45_91slug_93yMjQcjSjulMeta?.alias || [],
    redirect: _91id_93_45_91slug_93yMjQcjSjulMeta?.redirect,
    component: () => import("/app/pages/node/[id]-[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___en",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/en/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___fr",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/fr/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___es",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/es/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___de",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/de/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___nl",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/nl/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jOfXxJvwBLMeta?.name ?? "page-slug___ar",
    path: _91slug_93jOfXxJvwBLMeta?.path ?? "/ar/page/:slug()",
    meta: _91slug_93jOfXxJvwBLMeta || {},
    alias: _91slug_93jOfXxJvwBLMeta?.alias || [],
    redirect: _91slug_93jOfXxJvwBLMeta?.redirect,
    component: () => import("/app/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___en",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/en/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___fr",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/fr/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___es",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/es/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___de",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/de/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___nl",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/nl/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.name ?? "password-reset-user-institution-hash___ar",
    path: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.path ?? "/ar/password-reset/:user()-:institution()-:hash()",
    meta: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta || {},
    alias: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.alias || [],
    redirect: _91user_93_45_91institution_93_45_91hash_93x7pyfu1mvyMeta?.redirect,
    component: () => import("/app/pages/password-reset/[user]-[institution]-[hash].vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___en",
    path: indexYgRHYxGzjsMeta?.path ?? "/en/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___fr",
    path: indexYgRHYxGzjsMeta?.path ?? "/fr/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___es",
    path: indexYgRHYxGzjsMeta?.path ?? "/es/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___de",
    path: indexYgRHYxGzjsMeta?.path ?? "/de/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___nl",
    path: indexYgRHYxGzjsMeta?.path ?? "/nl/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: indexYgRHYxGzjsMeta?.name ?? "password-reset___ar",
    path: indexYgRHYxGzjsMeta?.path ?? "/ar/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    alias: indexYgRHYxGzjsMeta?.alias || [],
    redirect: indexYgRHYxGzjsMeta?.redirect,
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___en",
    path: searchfcnTL5fl5XMeta?.path ?? "/en/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___fr",
    path: searchfcnTL5fl5XMeta?.path ?? "/fr/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___es",
    path: searchfcnTL5fl5XMeta?.path ?? "/es/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___de",
    path: searchfcnTL5fl5XMeta?.path ?? "/de/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___nl",
    path: searchfcnTL5fl5XMeta?.path ?? "/nl/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___ar",
    path: searchfcnTL5fl5XMeta?.path ?? "/ar/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___en",
    path: estimateBvWtG1sZBQMeta?.path ?? "/en/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___fr",
    path: estimateBvWtG1sZBQMeta?.path ?? "/fr/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___es",
    path: estimateBvWtG1sZBQMeta?.path ?? "/es/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___de",
    path: estimateBvWtG1sZBQMeta?.path ?? "/de/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___nl",
    path: estimateBvWtG1sZBQMeta?.path ?? "/nl/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: estimateBvWtG1sZBQMeta?.name ?? "subscribe-estimate___ar",
    path: estimateBvWtG1sZBQMeta?.path ?? "/ar/subscribe/estimate",
    meta: estimateBvWtG1sZBQMeta || {},
    alias: estimateBvWtG1sZBQMeta?.alias || [],
    redirect: estimateBvWtG1sZBQMeta?.redirect,
    component: () => import("/app/pages/subscribe/estimate.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___en",
    path: indexSgs8AqJpW6Meta?.path ?? "/en/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___fr",
    path: indexSgs8AqJpW6Meta?.path ?? "/fr/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___es",
    path: indexSgs8AqJpW6Meta?.path ?? "/es/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___de",
    path: indexSgs8AqJpW6Meta?.path ?? "/de/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___nl",
    path: indexSgs8AqJpW6Meta?.path ?? "/nl/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: indexSgs8AqJpW6Meta?.name ?? "subscribe___ar",
    path: indexSgs8AqJpW6Meta?.path ?? "/ar/subscribe",
    meta: indexSgs8AqJpW6Meta || {},
    alias: indexSgs8AqJpW6Meta?.alias || [],
    redirect: indexSgs8AqJpW6Meta?.redirect,
    component: () => import("/app/pages/subscribe/index.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___en",
    path: successqYsoTEk1d4Meta?.path ?? "/en/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___fr",
    path: successqYsoTEk1d4Meta?.path ?? "/fr/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___es",
    path: successqYsoTEk1d4Meta?.path ?? "/es/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___de",
    path: successqYsoTEk1d4Meta?.path ?? "/de/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___nl",
    path: successqYsoTEk1d4Meta?.path ?? "/nl/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: successqYsoTEk1d4Meta?.name ?? "subscribe-success___ar",
    path: successqYsoTEk1d4Meta?.path ?? "/ar/subscribe/success",
    meta: successqYsoTEk1d4Meta || {},
    alias: successqYsoTEk1d4Meta?.alias || [],
    redirect: successqYsoTEk1d4Meta?.redirect,
    component: () => import("/app/pages/subscribe/success.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___en",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/en/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___fr",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/fr/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___es",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/es/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___de",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/de/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___nl",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/nl/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  },
  {
    name: teachers_45areauZqTSTDHI2Meta?.name ?? "teachers-area___ar",
    path: teachers_45areauZqTSTDHI2Meta?.path ?? "/ar/teachers-area",
    meta: teachers_45areauZqTSTDHI2Meta || {},
    alias: teachers_45areauZqTSTDHI2Meta?.alias || [],
    redirect: teachers_45areauZqTSTDHI2Meta?.redirect,
    component: () => import("/app/pages/teachers-area.vue").then(m => m.default || m)
  }
]